import React from 'react';
import {Link} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next'
import LanguageSelector from '../..//components/LanguageSelector';

const Nav = () => {
    const { t } = useTranslation();
    return (
        <ul className="mainmenu">
            <li className="has-droupdown"><Link to="#">Solutions</Link>
            <ul className="submenu">
                    <li><Link to="/mobileapp">Mobile app</Link></li>
                    <li><Link to="/webstore">Web store</Link></li>
                    <li><Link to="/dashboard">Dashboard module</Link></li>
                </ul>
            </li>
            <li className="has-droupdown"><Link to="#">Industries</Link>
            <ul className="submenu">
                    <li><Link to="">Lifts rental</Link></li>
                    <li><Link to="">Heavy equipment</Link></li>
                    <li><Link to="">Tools rental</Link></li>
                </ul>
            </li>
            <li><HashLink smooth to="/#pricing">{t("About")}</HashLink></li>
            <li><Link to="/contact">{t("Home")}</Link></li>
            <li><LanguageSelector /></li>
        </ul>
    )
}
export default Nav;
